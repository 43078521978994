.privacy{
    padding: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.privacy h1{
    color: #1A237E;
}

@media (max-width: 600px) {
    .privacy{
        padding: 130px 10px;
    }
}