.contact-us-section {
    display: flex;
    flex-direction: row;
    
    align-items: center;
    justify-content: space-around;
    padding: 50px 20px;
    
  }
  
  .form-container {
    width: 100%;
    max-width: 500px;
    margin-bottom: 30px;
  }
  
  .form-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-container input,
  .form-container textarea {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-container .btn {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .map-container {
    width: 100%;
    max-width: 500px;
    height: 400px;
    margin-top: 30px;
  }
  
  @media screen and (max-width: 768px) {
    .contact-us-section {
      flex-direction: column;
    }
  }
  