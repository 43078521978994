/* Prime Focus Section Styles */
.prime-focus {
    text-align: center;
    padding: 50px 20px;
    background-color: #1A237E;
    color: white;
    margin-top: 40px;
  }
  
  .prime-focus h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .prime-focus h2 {
    font-size: 20px;
    color: #f4a261;
    margin-bottom: 10px;
  }
  
  .focus-cards {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }
  
  .focus-card {
    background-color: #6C5CE7;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    text-align: center;
    color: white;
    transition: transform 0.3s ease;
  }
  
  .focus-card:hover {
    transform: scale(1.05);
  }
  
  .focus-card h3 {
    margin-top: 10px;
    font-size: 20px;
  }
  
  .focus-card p {
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .focus-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .focus-card {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .prime-focus h1 {
      font-size: 28px;
    }
  
    .focus-card p {
      font-size: 14px;
    }
  }
  