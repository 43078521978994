/* Background overlay and blur effect for second popup */
.second-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Higher than any other elements */
    backdrop-filter: blur(5px); /* Blur background */
  }
  
  .second-popup-form {
    background: white;
    border-radius: 10px;
    padding: 30px;
    width: 400px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 1100;
  }
  
  .second-popup-form h2 {
    margin-bottom: 15px;
  }
  
  .second-popup-form input,
  .second-popup-form textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .second-popup-form .btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-btn-float {
    background: none;
    border: none;
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  @media screen and (max-width: 600px) {
    .second-popup-form {
      width: 90%;
    }
  }
  