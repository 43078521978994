/* components/FloatingForm.css */
.floating-form {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
}

.form-content {
    background: white;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.form-content h2 {
    font-size: 18px;
    margin-bottom: 0px;
}

.form-content input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.form-content textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-content button[type="submit"] {
    width: 100%;
    padding: 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.minimized-icon {
    width: 50px;
    height: 50px;
    background-color: #1A237E;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
}

.icon{
    color: white;
}

.close-btn {
    background: none;
    border: none;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}


@media screen and (max-width: 600px) {
    .floating-form{
        padding-left: 30px;
        display: none;
    }
    .form-content {
        width: 90%;
        /* padding: 20px; */
    }

    .close-btn {
        background: none;
        border: none;
        font-size: 16px;
        position: absolute;
        top: 10px;
        right: 50px;
        cursor: pointer;
    }
    
}

