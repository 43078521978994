
* {
  box-sizing: border-box;
}
body {
  margin: 0;
}
.nav {
  top:0;
  width: 100%;
  background-color: #1A237E;
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 18px;
  position: fixed;
  height: 65px;
  backdrop-filter: blur(3px);
  transition: 0.5s;
  color:white;
  z-index: 100;
  
}

a {
  color: white;
  position: relative;
  font-size: 1.2em;
  margin-left: 50px;
}

nav a::after {
  content: '';
  background: #ffff;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 20px;
  left: 0;
  bottom: -3px;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .5s;
}

nav a:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}


.affix{
    
  padding: 0;
  background-color: #1A237E;
}

.nav > .nav-header {
  flex: 0.95;
  padding-left: .4rem;
  
}
.nav > .nav-header > .nav-logo{
  /* padding-top: 1rem;
  padding-left: 1rem; */
  height: 100%;
  display: flex;
  align-items: center;
}
.nav > .nav-header > .nav-logo img{
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.nav > .nav-header > .nav-logo h3{
  margin-left: 50px;
  cursor: pointer;
  user-select: none;

}

.nav > .nav-list {
  display: flex;
  gap: 2rem;
}
.nav > .nav-list > li {
  list-style-type: none;
}
.nav > .nav-list > li a {
  text-decoration: none;
  color: #efefef;
  /* color:black; */
  cursor: pointer;
}
.nav > #nav-check {
    display: none;
}

/* Dropdown Styles */
.dropdown {
  position: relative;
  /* display: flex;
  flex-direction: column;
 
  align-items: center;
  justify-content: center; */
}

.dropdown-menu {
  display: block;
  position: absolute;
  min-width: 150px;
  z-index: 1;
  top: 100%;
  left: 0;
  transition: opacity 0.3s ease;  
  background-color: rgba(0, 0, 0, 0.3);
  
}

.dropdown-menu li {
  padding: 10px 5px;
  cursor: pointer;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #efefef;
}

.dropdown-menu li:hover {
  background-color: #ffff;
  color: black;
}


@media (max-width: 780px) {
  .nav {
    padding: 0rem;
  }
  .nav > .nav-header > .nav-logo img{
    width: 100px;
  }
  .nav > .nav-header > .nav-logo h3{
    margin-left: 15px;
  }
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 0.2rem;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  
  .nav > .nav-list {
    backdrop-filter: blur(15px);
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    background-color: #1A237E  ;
    /* background-color: white; */
    color: black;
    height: 0;
    transition: all 0.3s ease-in;
    top: 45px;
    left: 0;
    overflow: hidden;
    

  }

  
  .nav > .nav-list > li {
    width: 100%;
    margin-top: 1rem;
  }
  .nav > #nav-check:checked ~ .nav-list {
    height: calc(100vh - 50px);
  }
  .dropdown-menu {
    width: 100%;
    position: static;
    display: none;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }
}