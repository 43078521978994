.search-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
.search-page{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background:rgba(0, 0, 0, 0.4) url("../../public/houses/elanmiracle.jpg");
  height: 400px;
  background-position:right;
  background-size: cover;
  background-blend-mode: darken;
}
.search-page .search-banner{
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-page .search-banner h1{
  color: white;
}
.search-page .search-banner img{
  margin-left: 30px;
  width: 30%;
  height: 100%;
}

.search-page input {
  width: 75%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}
.btn {
  text-decoration: none;
  color: white;
  /* background-color: #e63946; */
  background-color: #1A237E;
  padding: 15px 30px;
  font-size: 1.1em;
  border-radius: 30px;
  transition: background-color 0.3s ease;
  margin-right: 10px
}

.btn:hover {
  background-color: white;
  color: black;
}
.btn-model{
  text-decoration: none;
  color: white;
  /* background-color: #e63946; */
  background-color: #1A237E;
  padding: 10px 10px;
  font-size: 1em;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  margin-right: 10px;
  width:50%;
}


.property-list {
  padding: 30px 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.property-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.property-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.property-card p {
  font-size: 14px;
  margin-bottom: 15px;
}

.property-card h4 {
  font-size: 16px;
  color: green;
}
form{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-message {
  background-color: white;
  color: red;
  margin-top: 10px;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .search-page .search-banner img{
    margin-left: 0px;
    width: 100px;
  }
}