.footer {
    background-color: #2E2E2E;
    background-color: #1A237E;
    color: white;
  }
  
  .footer-social {
    background-color: #6C5CE7;
    /* background-color: #1A237E; */
    text-align: center;
    padding: 20px;
  }
  
  .footer-social p {
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .social-icons a {
    color: white;
    font-size: 20px;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #1A237E;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 30px 0;
  }
  
  .footer-column {
    margin: 15px;
    width: 500px;
  }
  
  .footer-column h6 {
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    border-bottom: 2px solid #6C5CE7;
    display: inline-block;
    padding-bottom: 5px;
  }
  
  .footer-column p {
    font-size: 14px;
    line-height: 1.6;
  }
  
  .footer-column ul {
    /* list-style: none; */
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 10px;
  }
  
  .footer-column ul li a {
    margin-left: 0;
    color: white;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-column ul li a:hover {
    text-decoration: underline;
  }
  
  .contact-info li {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
  }
  
  .footer-bottom {
    text-align: center;
    padding: 10px;
    background-color: #1C1C1C;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  @media (max-width: 768px) {
      
  .footer-bottom p {
    margin: 0;
    padding-bottom: 60px;
  }
  }