.properties-section {
    text-align: center;
    margin: 20px 0;
}

.properties-section h2 {
    font-size: 2.5em;
    color: #1d3557;
    margin-bottom: 20px;
}

.properties-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allows wrapping of items on smaller screens */
    gap: 20px;
    padding: 25px;
}

.property-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    transition: transform 0.3s ease;
    position: relative; /* Position for the badge */
}

.property-card:hover {
    transform: scale(1.05);
}

.property-badge {
    position: absolute;
    background-color: #f08a5d;
    color: #fff;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    top: 10px;
    left: 10px;
}

.property-badge.for-sale {
    background-color: #f08a5d;
}

.property-badge.for-rent {
    background-color: #e63946;
}

.property-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.property-details {
    padding: 15px;
    text-align: left;
}

.property-details h3 {
    font-size: 1.2em;
    margin-bottom: 5px;
    color: #1d3557;
}

.property-details p {
    font-size: 0.9em;
    color: #6c757d;
}

.price {
    font-size: 1.3em;
    font-weight: bold;
    color: #2a9d8f;
}

.property-details p {
    font-size: 0.9em;
    color: #6c757d;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* limits the number of lines to 2 */
    -webkit-box-orient: vertical;
  }
  
  .show-more-toggle {
    font-weight: bold;
    margin-left: 5px;
  }
  
  .btn {
    text-decoration: none;
    color: white;
    /* background-color: #e63946; */
    background-color: #1A237E;
    padding: 15px 30px;
    font-size: 1.1em;
    border-radius: 30px;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: white;
    color: black;
}
.show-more-btn-container {
    text-align: center;
    margin: 20px 0;
  }
  
  .properties-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .btn {
    text-decoration: none;
    color: white;
    background-color: #1A237E;
    padding: 15px 30px;
    font-size: 1.1em;
    border-radius: 30px;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: white;
    color: black;
  }
  

/* RESPONSIVE DESIGN */

/* Medium devices (tablets, 768px and above) */
@media (max-width: 1024px) {
    .properties-container {
        justify-content: space-around;
    }

    .property-card {
        width: 45%; /* Two items per row on tablet screens */
    }
}

/* Small devices (mobile phones, 600px and below) */
@media (max-width: 600px) {
    .properties-section h2 {
        font-size: 2em; /* Slightly smaller heading */
    }

    .property-card {
        width: 100%; /* One item per row on mobile screens */
    }

    .property-details h3 {
        font-size: 1em; /* Smaller title font size */
    }

    .price {
        font-size: 1.2em;
    }
}

/* Existing CSS for property cards */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .modal-content h2 {
    font-size: 2em;
    margin: 20px 0;
    color: #1d3557;
  }
  
  .modal-content p {
    font-size: 1em;
    margin-bottom: 20px;
    color: #6c757d;
  }
  
  .modal-content .price {
    font-size: 1.5em;
    font-weight: bold;
    color: #2a9d8f;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f08a5d;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1.2em;
    border-radius: 5px;
  }
  
  .close-modal:hover {
    background-color: #e63946;
  }
  
  /* Blur the background when the modal is open */
  .modal-overlay ~ .properties-container {
    filter: blur(5px);
  }
  