/* Customer Testimonials Section Styles */
.customer-testimonials {
    background-color: #1A237E;
    color: white;
    text-align: center;
    padding: 60px 20px;
    margin-top: 0px;
  }
  
  .customer-testimonials h2 {
    font-size: 36px;
    margin-bottom: 20px;
    position: relative;
  }
  
  .testimonials-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
    flex-wrap: wrap;
  }
  
  .testimonial-card {
    background-color: #6C5CE7;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    min-width: 280px;
    text-align: left;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .testimonial-card:hover {
    transform: scale(1.05);
  }
  
  .quote-icon {
    font-size: 40px;
    color: white;
  }
  
  .testimonial-card h3 {
    margin-top: 10px;
    font-size: 18px;
    color: white;
    text-align: right;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .testimonials-container {
      flex-direction: column;
      align-items: center;
    }
  
    .testimonial-card {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 600px) {
    .testimonials-container {
      flex-direction: column;
    }
  
    .testimonial-card {
      width: 100%; /* Show only one card at a time */
    }
  }
  